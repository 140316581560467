import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { Button } from '@frontiers-vue/button';

Vue.config.productionTip = false;

Vue.component('btn', Button);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
