import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

function lazyLoadView(view: string): any {
  return () => import(`@/views/${view}.vue`);
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: lazyLoadView('Home'),
      alias: '/home',
    },
    {
      path: '/unsubscribe',
      name: 'Unsubscribe',
      component: lazyLoadView('Unsubscribe'),
    },
    {
      path: '/globalunsubscribe',
      name: 'GlobalUnsubscribe',
      component: lazyLoadView('GlobalUnsubscribe'),
    },
    {
      path: '/confirm/:confirmationKey',
      name: 'Success',
      component: lazyLoadView('Success'),
    },
    {
      path: '/subscribe/:subscriptionTypeId',
      name: 'Subscribe',
      component: lazyLoadView('Subscribe'),
    },
    {
      path: '/subscribe/:subscriptionTypeId/:resourceId/:resourceValue',
      name: 'Subscribe',
      component: lazyLoadView('Subscribe'),
    },
    {
      path: '/preferences/:urlKey',
      name: 'Email Preferences',
      meta: {
        title: 'Email Preferences',
      },
      component: lazyLoadView('Preferences'),
    },
    {
      path: '/preferences/',
      name: 'Preferences Authenticated',
      meta: {
        title: 'Email Preferences',
      },
      component: lazyLoadView('Preferences'),
    },
    {
      path: '/error/:errorCode',
      name: 'Error Page',
      component: lazyLoadView('Error'),
    },
    {
      path: '*',
      name: 'Not Found',
      redirect: '/error/404',
    },
  ],
});
