








import '@/scss/index.scss';
import { Vue, Component } from 'vue-property-decorator';
import EventBus, { SHOW_TOAST_MESSAGE } from './core/event-bus';
import ToastMessage from '@/components/ToastMessage.vue';

@Component({
  components: { ToastMessage },
})
export default class App extends Vue {
  private toastType: string = '';
  private toastMessage: string = '';
  private toastToggle: boolean = false;

  private showToastMessage(message: string, type: string): void {
    if (message.length > 0) {
      this.toastType = type || 'Info';
      this.toastMessage = message;
      this.toastToggle = !this.toastToggle;
    }
  }

  private mounted(): void {
    EventBus.$on(SHOW_TOAST_MESSAGE, this.showToastMessage);
  }
}
