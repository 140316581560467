






import '../scss/components/toastMessage.scss';
import { Watch, Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class ToastMessage extends Vue {
    private get getClass(): string {
        // @ts-ignore
        const t: ToastType = ToastType[this.type];

        return `ToastMessage--${t}`;
    }

    @Prop({ default: 'Info'}) private type!: ToastType;
    @Prop({ default: '' }) private message!: string;
    @Prop({ default: false }) private toggle!: boolean;
    @Prop({ default: 3000 }) private time!: number;

    private show: boolean = false;

    @Watch('toggle')
    private toggle_updated(): void {
        this.show = true;
        setTimeout(this.hide, this.time);
    }

    private hide(): void {
        this.show = false;
    }
}

export enum ToastType {
    Info = 'info',
    Error = 'error',
}
